import { bindable, inject }                from 'aurelia-framework';
import { I18N }                            from 'aurelia-i18n';
import { PLATFORM }                        from 'aurelia-pal';
import { BaseListViewModel }               from 'base-list-view-model';
import { ReorderFieldsModal }              from 'modules/management/specifications/bulletins/bulletin-revision-fields/reorder-fields-modal';
import { BulletinRevisionFilesRepository } from 'modules/management/specifications/bulletins/bulletin-revisions/services/files-repository';
import { BulletinRevisionsRepository }     from 'modules/management/specifications/bulletins/bulletin-revisions/services/repository';
import { FilterFormSchema }                from 'modules/management/specifications/bulletins/filter-form-schema';
import { BulletinListingsModal }           from 'modules/management/specifications/bulletins/listings-modal';
import { BulletinSpecificationsModal }     from 'modules/management/specifications/bulletins/specifications-modal';
import { AppContainer }                    from 'resources/services/app-container';
import { Downloader }                      from 'resources/services/downloader';
import { BulletinsRepository }             from './services/repository';

@inject(AppContainer, BulletinsRepository, I18N, Downloader, FilterFormSchema, BulletinRevisionsRepository, BulletinRevisionFilesRepository)
export class ListBulletins extends BaseListViewModel {

    listingId = 'management-specifications-bulletins-listing';

    @bindable headerTitle    = 'listing.management.specifications.bulletins';
    @bindable newRecordRoute = 'management.specifications.bulletins.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     * @param bulletinRevisionsRepository
     * @param bulletinRevisionFilesRepository
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema, bulletinRevisionsRepository, bulletinRevisionFilesRepository) {
        super(appContainer);

        this.repository                      = repository;
        this.i18N                            = i18N;
        this.downloader                      = downloader;
        this.filterFormSchema                = filterFormSchema;
        this.bulletinRevisionsRepository     = bulletinRevisionsRepository;
        this.bulletinRevisionFilesRepository = bulletinRevisionFilesRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletins.manage',
            'management.specifications.bulletins.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('management.specifications.bulletins.view', { id: row.bulletin_id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.bulletins.manage', 'management.specifications.bulletins.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('management.specifications.bulletins.edit', { id: row.bulletin_id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.bulletins.manage', 'management.specifications.bulletins.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.bulletins.manage', 'management.specifications.bulletins.delete']),
            },
            actions:            [
                {
                    icon:    'icon-list-unordered',
                    tooltip: 'form.field.listings',
                    action:  (row) => this.openModal(row, BulletinListingsModal),
                    visible: (row) => row.hasListings && this.appContainer.authenticatedUser.can([
                        'management.specifications.bulletins.manage',
                        'management.specifications.bulletins.view',
                        'management.specifications.listings.manage',
                        'management.specifications.listings.view',
                    ]),
                },
                {
                    icon:    'icon-book3',
                    tooltip: 'form.field.specification-books',
                    action:  (row) => this.openModal(row.bulletin_id, BulletinSpecificationsModal),
                    visible: (row) => row.hasSpecifications && this.appContainer.authenticatedUser.can([
                        'management.specifications.bulletins.manage',
                        'management.specifications.bulletins.view',
                        'management.specifications.specifications.manage',
                        'management.specifications.specifications.view',
                    ]),
                },
                {
                    icon:    'icon-insert-template',
                    action:  (row) => this.openModal(row.id, ReorderFieldsModal),
                    visible: (row) => row.hasFields && this.appContainer.authenticatedUser.can([
                        'management.specifications.bulletins.manage',
                        'management.specifications.bulletins.edit',
                    ]),
                    tooltip: 'form.button.reorder-fields',
                },
            ],
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.bulletins'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.bulletins.manage', 'management.specifications.bulletins.view']),
                },
            ],
            selectable:         true,
            destroySelected:    true,
            actionsContextMenu: true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'test_code',
                    name:  'bulletins.test_code',
                    title: 'form.field.test-code',
                },
                {
                    data:  'name',
                    name:  'bulletin_translations.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'revision_number',
                    name:  'bulletin_revisions.revision_number',
                    title: 'form.field.revision-number',
                },
                {
                    data:  'norm',
                    name:  'bulletins.norm',
                    title: 'form.field.norm',
                },
                {
                    data:  'norm_edition',
                    name:  'bulletin_revisions.norm_edition',
                    title: 'form.field.norm-edition',
                },
                {
                    data:  'bulletin_model',
                    name:  'bulletin_revisions.bulletin_model',
                    title: 'form.field.bulletin-model',
                },
                {
                    data:            'effective_date',
                    name:            'bulletin_revisions.effective_date',
                    title:           'form.field.effective_date',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:    'observations',
                    name:    'bulletins.observations',
                    title:   'form.field.observations',
                    display: false,
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'bulletins.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:       'bulletin_model_file_submitted',
                    name:       'bulletin_model_file_submitted',
                    title:      'form.field.model-file',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/management/specifications/bulletins/custom-listing-cells/bulletin-model-file-submitted/index'),
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:       'bulletin_can_be_ordered',
                    name:       'bulletin_can_be_ordered',
                    title:      'form.field.order-fields',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/management/specifications/bulletins/custom-listing-cells/bulletin-can-be-ordered/index'),
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:       'listings',
                    name:       'listings',
                    title:      'form.title.listings',
                    type:       'custom-cell',
                    icon:       'icon-list-unordered',
                    show:       (row) => row.hasListings,
                    action:     (row) => this.openModal(row, BulletinListingsModal),
                    viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                    orderable:  false,
                    searchable: false,
                    visible:    !this.appContainer.authenticatedUser.can([
                        'management.specifications.bulletins.manage',
                        'management.specifications.bulletins.view',
                        'management.specifications.listings.manage',
                        'management.specifications.listings.view',
                    ]),
                },
            ],
        };
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.appContainer.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}
